import React from "react";

// Customizable Area Start
import { Container, Box, Button, Typography } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import StopwatchController, { Props, configJSON } from "./StopwatchController";

export default class Stopwatch extends StopwatchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6" style={webStyle.timing}>
              {Math.floor(this.state.counter / 60)
                .toString()
                .padStart(2, "0")}
              {" : "}
              {Math.floor(this.state.counter % 60)
                .toString()
                .padStart(2, "0")}
              {" : "}
              {Math.floor((this.state.miliseconds / 60) * 100)
                .toString()
                .padStart(2, "0")}
            </Typography>
            <Box style={webStyle.buttonWrapper}>
              <Button
                data-test-id="btnStart"
                color={"primary"}
                disabled={this.state.isStartDisable}
                onClick={() => this.onStart()}
                variant="contained"
                style={
                  this.state.isStartDisable
                    ? webStyle.disableButton
                    : webStyle.button
                }
              >
                {configJSON.start}
              </Button>

              <Button
                data-test-id="btnPause"
                color={"primary"}
                disabled={this.state.isPauseDisable}
                onClick={() => this.onPause()}
                variant="contained"
                style={
                  this.state.isPauseDisable
                    ? webStyle.disableButton
                    : webStyle.button
                }
              >
                {configJSON.pause}
              </Button>

              <Button
                data-test-id="btnClear"
                color={"primary"}
                disabled={this.state.isClearDisable}
                onClick={() => this.onClear()}
                variant="contained"
                style={
                  this.state.isClearDisable
                    ? webStyle.disableButton
                    : webStyle.button
                }
              >
                {configJSON.clear}
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  timing: {
    fontSize: 32,
    marginTop: 30,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#2196F3",
    margin: 10,
  },
  disableButton: {
    backgroundColor: "#2196F3",
    margin: 10,
    opacity: 0.7,
  },
};
// Customizable Area End
