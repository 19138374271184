import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  time: any;
  counter: number;
  miliseconds: number;
  isStartDisable: boolean;
  isPauseDisable: boolean;
  isClearDisable: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StopwatchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.onStart = this.onStart.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onClear = this.onClear.bind(this);
    this.start = this.start.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      time: null,
      counter: 0,
      miliseconds: 0,
      isStartDisable: false,
      isPauseDisable: true,
      isClearDisable: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    // Customizable Area Start
    clearInterval(this.state.time);
    // Customizable Area End
  }

  // Customizable Area Start
  start() {
    let time = setInterval(() => {
      var num = this.state.miliseconds + 1,
        count = this.state.counter;
      if (Number(this.state.miliseconds) === 59) {
        count = this.state.counter + 1;
        num = 0;
      }
      this.setState({
        counter: count,
        miliseconds: num,
      });
    }, 0);
    this.setState({ time });
  }

  onStart() {
    this.start();
    this.setState({
      isStartDisable: true,
      isPauseDisable: false,
      isClearDisable: false,
    });
  }

  onPause() {
    clearInterval(this.state.time);
    this.setState({
      isStartDisable: false,
      isPauseDisable: true,
      isClearDisable: false,
    });
  }

  onClear() {
    clearInterval(this.state.time);
    this.setState({
      time: null,
      counter: 0,
      miliseconds: 0,
      isStartDisable: false,
      isPauseDisable: true,
      isClearDisable: true,
    });
  }

  // Customizable Area End
}
